<template lang="pug">
.customer-custom-fields-data.card
  .card-header {{ $t('title') }}
    .fa-pull-right
      .btn-group.btn-group-sm
        button.btn.btn-primary(
          v-if           = '!editing'
          :disabled      = 'isLoading'
          @click.prevent = 'toggleEditing()'
        ) {{ $t("common:edit") }}
  .card-body
    div.loading-center(v-if="isLoading")
      i.fa.fa-spinner.fa-pulse.ml-3
    form.form-horizontal(
      v-else
      novalidate
      @submit.prevent = 'onSubmit'
    )
      template(v-if = 'editing')
        .row
          .col
            .input-group.input-group-sm.mb-2
              fi-select(
                v-model = 'selectedField'
                :options = 'optionsFromCustomFields'
                required
                sm
              )
              .input-group-append
                button.btn.btn-primary(
                  :disabled      = 'saving || !optionsFromCustomFields.length'
                  @click.prevent = 'addField'
                ) {{ $t('common:add') }}
                  i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        fi-custom-field-item(
          v-for     = '(customField, index) in customFieldsData'
          v-if="selectedFields[customField.customerCustomFieldId] && selectedFields[customField.customerCustomFieldId].customField"
          :key      = 'index'
          v-model   = 'customField.customFieldValue'
          :options  = 'selectedFields[customField.customerCustomFieldId].customField'
          :disabled = '!selectedFields[customField.customerCustomFieldId].enabled'
          :required = 'customField.required'
          editing
          @remove   = 'removeField(index)'
        )
        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        )
          |{{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing(false)'
        ) {{ $t('common:cancel') }}
      template(v-else)
        fi-custom-field-item(
          v-for     = 'field in customFieldsData'
          v-if="selectedFields[field.customerCustomFieldId] && selectedFields[field.customerCustomFieldId].customField"
          :key      = 'field.id'
          :required = 'field.required'
          :options  = 'selectedFields[field.customerCustomFieldId].customField'
          :value    = 'field.customFieldValue'
        )
</template>

<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiCustomFieldItem from '@/components/FiCustomFieldItem'
import FiDataField from '@/components/FiDataField'
import { sortClassifiers } from '@/helpers'

export default {
  name: 'customer-custom-fields-data',
  components: { FiDataField, FiCustomFieldItem, FiSelect, FiFormField },
  props: {
    customFields: {
      type: Array,
      default: () => []
    },
    loader: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    editing: false,
    selectedField: '',
    customFieldsData: []
  }),

  computed: {
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    },
    optionsFromCustomFields () {
      return sortClassifiers(this.options.filter(({ enabled, required }) => enabled && !required)
        .map(({ id, customField, ...field }) => ({ ...field, customField, text: customField.name, value: id }))
        .filter(({ value }) => !Object.keys(this.selectedFields).includes(`${value}`)))
    },
    selectedFields () {
      return this.customFieldsData.reduce((res, { customerCustomFieldId }) => {
        const field = this.options.find(({ id }) => id === customerCustomFieldId)
        if (field?.customField) {
          res[customerCustomFieldId] = field
        }
        return res
      }, {})
    }
  },

  async created () {
    await this.resetData()
  },
  mounted () {
    this.resetData()
  },

  methods: {
    toggleEditing (state = !this.editing) {
      this.resetData()
      this.editing = state
    },
    addField () {
      const selectedOption = this.optionsFromCustomFields.find(({ value }) => value === this.selectedField)
      const defaultValue = selectedOption?.customField?.defaultValues?.find(({ isEnabled }) => isEnabled)
      this.customFieldsData.push({
        customerCustomFieldId: this.selectedField,
        customFieldValue: defaultValue?.classifierValue || ''
      })
      this.selectedField = ''
    },
    removeField (index) {
      this.customFieldsData.splice(index, 1)
    },
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
      const requiredFields = this.options
        .filter(({ enabled, required }) => enabled && required)
        .filter(({ id }) => !this.customFieldsData.some(({ customerCustomFieldId }) => customerCustomFieldId === id))
        .map(({ id, required }) => ({ customerCustomFieldId: id, customFieldValue: undefined, required }))
      this.customFieldsData = [
        ...this.customFields.map(({ customFieldValue, customerCustomFieldId, required }) => ({
          customerCustomFieldId,
          customFieldValue,
          required
        })),
        ...requiredFields
      ]
    },
    onSubmit () {
      this.$emit('submit', {
        data: this.customFieldsData.filter(({ customerCustomFieldId }) => this.selectedFields[customerCustomFieldId]?.enabled),
        done: () => this.resetData()
      })
    }
  }
}
</script>

<i18n>
en:
  title: "Custom fields"
et:
  title: "Custom fields"
ru:
  title: "Custom fields"
</i18n>

<style scoped lang="scss">
.loading-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-center .b-spinner {
  width: 3rem;
  height: 3rem;
  color: #007bff;
}
</style>
